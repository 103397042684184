<template>
  <div id="mnotify_dashboard">
    <div class="mnotify_dashboard_header">
      <div class="logo_section p-2">
        <img
          width="4rem"
          class="notify-logo-admin"
          src="@/assets/img/bms-01-text.png"
          alt="mNotify"
        />
      </div>
      <el-button
        @click="drawer = true"
        type="primary"
        style="margin-left: 16px; font-size: 1.5rem; padding: 0.5rem"
        icon="el-icon-s-fold"
        class="mobile-button"
      >
      </el-button>

      <div class="top_menu_items">
        <changeAppLanguage
          style="padding-top: 1rem; margin-right: 3rem"
          v-if="$route.path.includes('admin')"
        ></changeAppLanguage>
        <div
          class="actions"
          style="display: flex; gap: 2rem; align-items: center"
        >
          <div class="dropdown">
            <button
              class="btn actions_toggler dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ adminName }}
              <span style="margin-left: 0.5em"
                ><i class="fas fa-chevron-down" style="color: #e5e5e5"></i
              ></span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <!-- <router-link class="dropdown-item" to="/profile">Profile</router-link> -->
              <!-- <a class="dropdown-item" href="#">Settings</a> -->
              <!-- <router-link class="dropdown-item" to="/developer">Developer</router-link> -->
              <a class="dropdown-item" @click.prevent="logout" href="#"
                >Logout</a
              >
              <a
                class="dropdown-item"
                v-if="isFromOmniChannel"
                @click="backToOmniChannel"
                >Back to Omni Channel</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mnotify_dashbaord_body">
      <div id="mnotify_dashboard_sidebar">
        <ul class="side_nav">
          <li
            :class="[$route.path.includes('/admin/users') ? 'active' : '']"
            class="side_nav_item"
          >
            <router-link to="/admin/users/all" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path.includes('/admin/users')"
                height="20px"
                width="20px"
                src="@/assets/Profile_active.svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Profile.svg"
                alt="img"
              />
              Users
            </router-link>
          </li>
          <li
            :class="[$route.path.includes('/admin/sender_ids') ? 'active' : '']"
            class="side_nav_item"
          >
            <router-link to="/admin/sender_ids" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path.includes('/admin/sender_ids')"
                height="20px"
                width="20px"
                src="@/assets/lock_active.svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Lock.svg"
                alt="img"
              />
              Sender IDs
            </router-link>
          </li>
          <li
            :class="[
              $route.path.includes('/admin/fraud-detection') ? 'active' : '',
            ]"
            class="side_nav_item"
          >
            <router-link to="/admin/fraud-detection" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path.includes('/admin/fraud-detection')"
                height="20px"
                width="20px"
                src="@/assets/lock_active.svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Lock.svg"
                alt="img"
              />
              Fraud Detection
            </router-link>
          </li>

          <li
            :class="[
              $route.path.includes('/admin/wallet_history_fr') ? 'active' : '',
            ]"
            class="side_nav_item"
          >
            <router-link to="/admin/wallet_history_fr" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path.includes('/admin/wallet_history_fr')"
                height="20px"
                width="20px"
                src="@/assets/Wallet_active.svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Wallet.svg"
                alt="img"
              />
              Wallet History
            </router-link>
          </li>
          <li
            :class="[$route.path == '/admin/mass_messages' ? 'active' : '']"
            class="side_nav_item"
            v-if="!disallowedUsers"
          >
            <router-link to="/admin/mass_messages" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path == '/admin/mass_messages'"
                height="20px"
                width="20px"
                src="@/assets/message_active.svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/default.svg"
                alt="img"
              />
              Mass Messages
            </router-link>
          </li>
          <li
            :class="[$route.path.includes('finance') ? 'active' : '']"
            class="side_nav_item"
            v-if="!disallowedUsers"
          >
            <router-link to="/admin/finance" class="side_nav_link">
              <div class="" style="width: 100%">
                <img
                  class="mr-3 ml-2"
                  v-if="$route.path.includes('/admin/finance')"
                  height="20px"
                  width="20px"
                  src="@/assets/Wallet__active.svg"
                  alt="img"
                />
                <img
                  class="mr-3 ml-2"
                  v-else
                  height="20px"
                  width="20px"
                  src="@/assets/Wallet.svg"
                  alt="img"
                />
                Finance
              </div>
            </router-link>
          </li>
          <!-- /birthday-settings -->
          <li
            :class="[$route.path == '/admin/notifications' ? 'active' : '']"
            class="side_nav_item"
            v-if="!disallowedUsers"
          >
            <router-link to="/admin/notifications" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path == '/admin/notifications'"
                height="20px"
                width="20px"
                src="@/assets/Notification.svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Notification (1).svg"
                alt="img"
              />
              Notifications
            </router-link>
          </li>
          <li
            :class="[$route.path.includes('resellers') ? 'active' : '']"
            class="side_nav_item"
            v-if="!disallowedUsers"
          >
            <router-link to="/admin/resellers/pending" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path.includes('resellers')"
                height="20px"
                width="20px"
                src="@/assets/Ticket Star (1).svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Ticket Star.svg"
                alt="img"
              />
              Resellers
            </router-link>
          </li>
          <li
            :class="[$route.path.includes('/admin/reporting') ? 'active' : '']"
            class="side_nav_item"
          >
            <router-link
              :to="
                !disallowedUsers
                  ? '/admin/reporting/industries'
                  : '/admin/reporting/message_type'
              "
              class="side_nav_link"
            >
              <img
                class="mr-3 ml-2"
                v-if="$route.path.includes('/admin/reporting')"
                height="20px"
                width="20px"
                src="@/assets/reporting_active.svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/reporting.svg"
                alt="img"
              />
              Reporting
            </router-link>
          </li>
          <li
            :class="[
              $route.path.includes('/admin/promo_codes') ||
              $route.path.includes('/admin/referrals')
                ? 'active'
                : '',
            ]"
            class="side_nav_item"
          >
            <router-link
              :to="!disallowedUsers ? `/admin/promo_codes` : `/admin/referrals`"
              class="side_nav_link"
            >
              <img
                class="mr-3 ml-2"
                v-if="
                  $route.path.includes('/admin/promo_codes') ||
                  $route.path.includes('/admin/referrals')
                "
                height="20px"
                width="20px"
                src="@/assets/Ticket Star (1).svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Ticket Star.svg"
                alt="img"
              />
              <span v-if="disallowedUsers">Referrals</span>
              <span v-else> Promos & Referrals</span>
            </router-link>
          </li>
          <li
            :class="[$route.path == '/admin/industries' ? 'active' : '']"
            class="side_nav_item"
            v-if="!disallowedUsers"
          >
            <router-link to="/admin/industries" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path == '/admin/industries'"
                height="20px"
                width="20px"
                src="@/assets/Ticket Star (1).svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Ticket Star.svg"
                alt="img"
              />
              Industries
            </router-link>
          </li>
          <li
            :class="[
              $route.path == '/admin/international_rates' ? 'active' : '',
            ]"
            class="side_nav_item"
            v-if="!disallowedUsers"
          >
            <router-link to="/admin/international_rates" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path == '/admin/international_rates'"
                height="20px"
                width="20px"
                src="@/assets/Ticket Star (1).svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Ticket Star.svg"
                alt="img"
              />
              International Rates
            </router-link>
          </li>
          <!-- Settings -->
          <li
            :class="[$route.path.includes('/admin/settings') ? 'active' : '']"
            class="side_nav_item"
          >
            <router-link to="/admin/settings" class="side_nav_link">
              <img
                class="mr-3 ml-2"
                v-if="$route.path.includes('admin/settings')"
                height="20px"
                width="20px"
                src="@/assets/SettingActive.svg"
                alt="img"
              />
              <img
                class="mr-3 ml-2"
                v-else
                height="20px"
                width="20px"
                src="@/assets/Setting.svg"
                alt="img"
              />
              Settings
            </router-link>
          </li>
        </ul>
        <div class="__copy">&copy; mNotify {{ fullYear }}</div>
      </div>
      <div id="motify_dashboard_main">
        <router-view />
      </div>
      <el-drawer :visible.sync="drawer" :direction="direction" size="70%">
        <template slot="title">
          <img width="100px" src="@/assets/img/bms-01-text.png" alt="mNotify" />
        </template>
        <MobileMenu></MobileMenu>
      </el-drawer>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import store from "@/state/store.js";
  import Storage from "../../services/storage";
  import mobile_menu from "./mobile_menu.vue";
  export default {
    components: {
      MobileMenu: mobile_menu,
    },
    data() {
      return {
        active: "",
        showMenu: true,
        isEnglish: true,
        isFromOmniChannel: false,
        drawer: false,
        direction: "ltr",
      };
    },
    methods: {
      logout() {
        let storage = new Storage();
        storage.clearSavedData("auth.admin");
        storage.clearSavedData("token");
        storage.clearSavedData("source");
        store.dispatch("auth/logout");
        window.location.reload();
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      backToOmniChannel() {
        window.location.href = process.env.VUE_APP_OMNICHANNEL_URL;
      },
    },
    computed: {
      adminName() {
        let storage = new Storage();
        let adminData = storage.getSavedState("auth.admin");
        return adminData && adminData.name ? adminData.name : "";
      },
      fullYear() {
        return new Date().getFullYear();
      },
      url() {
        return process.env.VUE_APP_ENGINE_URL === "https://staging.mnotify.com";
      },
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
    mounted() {
      $("#close").hide();
      $("#open").click(function () {
        $("#open").hide();
        $("#close").show();
        //toggle side menu
        $("#mnotify_dashboard_sidebar").toggleClass("showsidebar");
      });

      $("#close").click(function () {
        $("#open").show();
        $("#close").hide();
        $("#mnotify_dashboard_sidebar").toggleClass("showsidebar");
      });
    },
    created() {
      let storage = new Storage();
      let source = storage.getSavedState("source");
      if (source && source === "omniChannel") {
        this.isFromOmniChannel = true;
      }
    },
  };
</script>

<style scoped lang="scss">
  #mnotify_dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfb;
  }

  .__copy {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #484a4f;
    margin-top: 4em;
    margin-bottom: 4em;
  }

  ._channel_active {
    border-radius: 5px;
    background-color: rgba(109, 34, 242, 0.1) !important;
  }

  .active_color {
    color: #f7921c !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
  }

  .mnotify_dashboard_header {
    height: 100px;
    background-color: #fbfbfb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-right: 2rem;
    padding-right: 2rem;

    .logo_section {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      button {
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 2.2em;
        transition: all 0.5s;
      }
    }

    .top_menu_items {
      width: 100%;
      height: inherit;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .records {
        width: 200px;
        height: 50px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 1em;
        padding-left: 1em;

        .icon {
          width: 30px;
          opacity: 0.3;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .text {
          padding-left: 0px;
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
          padding-top: 0.4em;

          .message {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding-left: 0px !important;

            p {
              font-family: "Graphik Regular";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 15px;
              text-transform: capitalize;
              color: #484a4f;
              opacity: 0.5;
              text-transform: capitalize;
            }
          }

          p {
            margin: 0px;
            padding: 0px;
          }

          .amount {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0px;
            margin-top: 0.2em;

            ._total {
              font-family: "Graphik Regular";
              font-style: normal;
              font-weight: 600;
              font-size: 21px;
              line-height: 14px;
              text-transform: capitalize;
              color: #484a4f;
            }

            .badge {
              background: #f7921c;
              border-radius: 14px;
              height: 15px;
              font-family: "Graphik Regular";
              font-style: normal;
              font-weight: 500;
              font-size: 8px;
              line-height: 9px;
              text-transform: capitalize;
              color: #ffffff;
              display: flex;
              align-items: center;
              margin-right: 1.5em;
            }
          }
        }
      }

      .language {
        display: flex;
        margin-right: 2em;
        margin-left: 2em;

        .my-dropdown-toggle::after {
          content: none;
        }

        .help-btn {
          background-color: transparent;
          border: none;
          outline: none;

          .fa-question-circle {
            font-size: 24px;
            color: #d4d8e2;
          }
        }
      }
    }
  }

  .divider {
    opacity: 0.3;
    width: 1px;
    background-color: #d4d8e2;
    height: 70%;
  }

  .mnotify_dashbaord_body {
    width: 100%;
    height: 100%;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: row;
  }

  #mnotify_dashboard_sidebar {
    width: 200px;
    // min-height: calc(100vh - 100px);
    transition: all 0.5s;
    // padding-top: 2em;
    background-color: #fbfbfb;

    .side_nav {
      list-style: none;
      margin: 0px;
      padding: 0px;
      max-height: min(100vh - 100px, 100%);
      overflow-y: auto;
      padding-right: 10px;

      .side_nav_item {
        padding-top: 0.8em;
        padding-left: 0.5em;
        height: 50px;
      }

      .side_nav_link {
        text-decoration: none;
        width: inherit;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        text-transform: capitalize;
        color: #484a4f;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .side_nav::-webkit-scrollbar {
    width: 3px;
    display: none;
  }
  .side_nav::-webkit-scrollbar-thumb {
    background-color: #f7921c;
    border-radius: 10px;
  }

  .side_nav:hover::-webkit-scrollbar {
    display: block;
  }

  #motify_dashboard_main {
    width: calc(100% - 200px);
    height: 100%;
    padding: 1em;
    // margin-left:2rem;
  }

  .showsidebar {
    display: block !important;
    position: relative;
    z-index: 100;
  }

  /* hide toggle btn */
  #open,
  #close {
    display: none;
  }

  // dropdown menu
  .my-dropdown-toggle {
    outline: none;
    border: none;
    background-color: transparent;
  }

  .dropdown-menu {
    border: 1px solid #fff;
    background-color: #fff;
  }

  .dropdown-item:focus,
  .dropdown-item:active {
    background-color: transparent !important;
  }

  .dropdown-item {
    font-family: "Graphik Regular";
    padding: 1em;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #484a4f;
  }

  //.actions
  .actions {
    .actions_toggler {
      outline: none;
      border: none;
      background-color: transparent;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      text-transform: capitalize;
      color: #484a4f;
    }
  }

  .actions_toggler::after {
    content: none !important;
  }

  .active {
    background: rgba(247, 146, 28, 0.1) !important;
    border-radius: 5px;
    height: 50px;
    margin-left: 1em;

    a {
      color: #f7921c !important;
      padding-top: 0.3em;
      font-weight: bolder;
    }
  }

  .__reporting__sub_nav_active {
    background-color: rgba(109, 34, 242, 0.1) !important;
    color: #f7921c !important;
  }

  .__sub__menu {
    padding-left: 3em;
  }

  ._switch {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 159.3%;
    margin-left: 1.5em;
    text-transform: capitalize;
    color: #484a4f;
  }

  .mobile-button {
    display: none;
  }

  @media screen and (max-width: 998px) {
    #open,
    #close {
      display: inline;
    }

    #mnotify_dashboard_sidebar {
      display: none;
    }

    #motify_dashboard_main {
      width: 100%;
      // position: fixed;
    }

    .mobile-button {
      display: block;
    }

    .notify-logo-admin {
      display: none;
    }
  }

  #mnotify_dashboard {
    height: 100vh;
    overflow: hidden;
  }

  .notify-logo-admin {
    object-fit: contain;
    width: 10rem;
  }
</style>
